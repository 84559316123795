// ScanTicket.tsx
import { useEffect, useState } from 'react';
import { Registration, UpdateRegistrationInput, RegistrationStatusEnum, Participation, CreateParticipationInput } from './API';
import { updateRegistrationDetails } from './mutations/processUpdateRegistration';
import { createParticipationRecord } from './mutations/processCreateParticipation';
import CameraSelect from "./CameraSelect";
import VideoStream from './VideoStream';
import ScanResult from './ScanResult';

import './css/ScanResult.css';

interface ScanTicketProps {
  eventId: string;
  attendees: Registration[];
  handler: (result: Registration | null) => void;
  goBack: () => void;
  setAttendees: React.Dispatch<React.SetStateAction<Registration[]>>;
}

function ScanTicket(props: ScanTicketProps) {
  const {
    handler,
    goBack,
    attendees,
    setAttendees
  } = props;
  const [deviceId, setDeviceId] = useState<string | null>(null);
  const [hasScanned, setHasScanned] = useState<boolean>(false);
  const [validAttendee, setValidAttendee] = useState<Registration | null>(null);
  const [message, setMessage] = useState("Checking Access...");
  const [devicesAvailable, setDevicesAvailable] = useState<boolean>(false);
  const [scanningEnabled, setScanningEnabled] = useState<boolean>(true);
  const [scannerAttendees, setScannerAttendees] = useState<Registration[]>(attendees);

  useEffect(() => {
    setScannerAttendees(attendees);
  }, [attendees]);

  function handleScanResult(qrString: string) {
    setHasScanned(true);

    let qrEventId = "";
    let qrRegistrationId = "";

    try {
      const url = new URL(qrString);
      const qParam = url.searchParams.get('q');
      if (qParam) {
        [qrEventId, qrRegistrationId] = qParam.split(':');
      }
    } catch (e) {
      console.log("Scanned string is not a URL, treating as registrationId");
      qrRegistrationId = qrString;
    }

    const found = (props.eventId === qrEventId || !qrEventId) && scannerAttendees.find(attendee => qrRegistrationId === attendee.registrationId);
    console.log("found: ", found);

    if (found && found.registrationStatus !== "REGISTERED" && found.registrationStatus !== "PARTIAL_CHECK_IN") {
      let message = "";
      switch (found.registrationStatus) {
        case "CANCELLED":
          message = "Ticket has been Cancelled";
          break;
        case "CHECKED_IN":
          message = "Ticket has already been used to Check In";
          break;
        default:
          message = "Invalid ticket";
          break;
      }
      setMessage(message);
      setValidAttendee(null);
    } else if (found) {
      setValidAttendee(found || null);
      setMessage('');
    }

    setScanningEnabled(false);
  }

  async function handleAcknowledgeSingle() {
    await handleAcknowledge(false);
    resetScannerState(); // Reset the scanner state after check-in
  }
  
  async function handleAcknowledgeAll() {
    await handleAcknowledge(true);
    resetScannerState(); // Reset the scanner state after check-in
  }
  
  function resetScannerState() {
    setHasScanned(false);
    setScanningEnabled(true);
    setValidAttendee(null);  // Reset the validAttendee state
    setMessage("Checking Access...");  // Reset the message
  }

  async function handleAcknowledge(all: boolean) {
    if (!validAttendee) return;
    const newCheckedInCount = all ? validAttendee.guests : (validAttendee.checkedInCount ?? 0) + 1;
    const guests = validAttendee.guests ?? 1;
    const registrationStatus = all ? RegistrationStatusEnum.CHECKED_IN : newCheckedInCount >= guests ? RegistrationStatusEnum.CHECKED_IN : RegistrationStatusEnum.PARTIAL_CHECK_IN;

    const actorEmail = localStorage.getItem('kc_email');
    const registrationData: UpdateRegistrationInput = {
      eventId: validAttendee.eventId,
      registrationId: validAttendee.registrationId,
      checkedInCount: newCheckedInCount,
      registrationStatus: registrationStatus
    };
    const participationData: CreateParticipationInput = {
      eventId: validAttendee.eventId,
      registrationId: validAttendee.registrationId,
      actorEmail,
      actionType: "CHECK_IN",
      checkedInCount: newCheckedInCount,
      participationStatus: registrationStatus,
      userAgent: navigator.userAgent
    };

    try {
      const updatedAttendee = await updateRegistrationDetails(registrationData) as Registration;
      console.log('updatedAttendee: ', updatedAttendee);

      const createdParticipation = await createParticipationRecord(participationData) as unknown as Participation;
      console.log('createdParticipation: ', createdParticipation);

      // Update scannerAttendees state
      setScannerAttendees((prevAttendees) =>
        prevAttendees.map((attendee) =>
          attendee.registrationId === validAttendee.registrationId
            ? { ...attendee, ...updatedAttendee }
            : attendee
        )
      );

      // Update parent attendees state
      setAttendees((prevAttendees) =>
        prevAttendees.map((attendee) =>
          attendee.registrationId === validAttendee.registrationId
            ? { ...attendee, ...updatedAttendee }
            : attendee
        )
      );

      setValidAttendee(updatedAttendee);
      setHasScanned(false);
      setScanningEnabled(true);
      handler(updatedAttendee);
    } catch (err) {
      console.log("Encountered an error updating status.", err);
      alert("Error: Unable to update registration. Please check your network connection and try again.");
      setMessage("Encountered an error updating status.");
      setHasScanned(false);
      setScanningEnabled(true);
    }
  }

  function scanMore() {
    setHasScanned(false);
    setScanningEnabled(true);
    setValidAttendee(null);  // Reset the validAttendee state
    setMessage("Checking Access...");  // Reset the message  
  }

  useEffect(() => {
    navigator.mediaDevices.getUserMedia({
      video: true
    }).then(() => {
      setDevicesAvailable(true);
    }).catch((err: Error) => {
      console.log(err);
      if (typeof navigator.mediaDevices === 'undefined') {
        setMessage("Unable to access media, check SSL settings.");
      } else if (err.name === "NotAllowedError") {
        setMessage("Please grant this page permission to access your camera.");
      } else if (err.name === "OverconstrainedError") {
        setMessage("Resolution is not supported by your device.");
      } else {
        setMessage("An unknown error has occurred.");
      }
    });
  }, []);

  function capitalizeFirstLetter(str: string) {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return devicesAvailable ? (
    <div className="scan-container">
      <div className="video-container">
        <VideoStream
          deviceId={deviceId}
          setScanResult={handleScanResult}
          disableScanning={!scanningEnabled}
          // highlightScanRegion={true}
          // highlightCodeOutline={true}
          maxScansPerSecond={50}
        />
        {hasScanned && (
          <div>
            <ScanResult isValid={validAttendee ? true : false}>
              {validAttendee && (
                <div className='scan-result-inner-container'>
                  Registrant:
                  <b>
                    <br />
                    {capitalizeFirstLetter(validAttendee.firstName)} {capitalizeFirstLetter(validAttendee.lastName)}
                  </b>
                  <br />
                  {validAttendee.checkedInCount} / {validAttendee.guests} Checked In
                  <br />
                  <br />
                  {validAttendee.live === false && "TEST REGISTRATION"}
                </div>
              )}
              {!validAttendee && (<div><b>Invalid Ticket</b><br />{message}</div>)}
              <div className="controls" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px', marginTop: '20px' }}>
                <button
                  className="checkin-button"
                  disabled={!validAttendee}
                  onClick={() => {
                    handleAcknowledgeSingle().catch((err) => console.error("Failed to acknowledge", err));
                  }}
                  style={{
                    width: '100%',
                    maxWidth: '300px',
                    padding: '15px 25px',
                    borderRadius: '3px',
                    backgroundColor: validAttendee ? 'var(--color-primary-blue)' : 'var(--color-border-gray)',
                    color: '#FFFFFF',
                    cursor: validAttendee ? 'pointer' : 'default',
                    border: 'none',
                    textAlign: 'center',
                  }}
                >
                  Check In
                </button>
                <button
                  className="checkin-button"
                  disabled={!validAttendee}
                  onClick={() => {
                    handleAcknowledgeAll().catch((err) => console.error("Failed to acknowledge", err));
                  }}
                  style={{
                    width: '100%',
                    maxWidth: '300px',
                    padding: '15px 25px',
                    borderRadius: '3px',
                    backgroundColor: validAttendee ? 'var(--color-primary-blue)' : 'var(--color-border-gray)',
                    color: '#FFFFFF',
                    cursor: validAttendee ? 'pointer' : 'default',
                    border: 'none',
                    textAlign: 'center',
                  }}
                >
                  Check In ALL
                </button>
                <button
                  className="cancel-button"
                  onClick={scanMore}
                  style={{
                    width: '100%',
                    maxWidth: '300px',
                    padding: '15px 25px',
                    borderRadius: '3px',
                    backgroundColor: '#B3C7E6',
                    color: '#FFFFFF',
                    cursor: 'pointer',
                    border: 'none',
                    textAlign: 'center',
                  }}
                >
                  Cancel
                </button>
              </div>
            </ScanResult>
          </div>
        )}
      </div>

      <div className="video-controls">
        <CameraSelect changeInputStream={setDeviceId} />
        <button className='event-button' onClick={goBack}>Back to Event Details</button>
      </div>
    </div>
  ) : (
    <p>{message}</p>
  );
}

export default ScanTicket;