/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createEvent = /* GraphQL */ `mutation CreateEvent($input: CreateEventInput!) {
  createEvent(input: $input) {
    eventId
    version
    sourceSiteId
    sourceName
    eventName
    ownerEmail
    eventKey
    ownerFirstName
    ownerLastName
    crmId
    live
    date
    endDate
    startTime
    endTime
    locale
    location
    room
    address
    address2
    city
    state
    zipCode
    description
    calendarStatus
    department
    marketingEffort
    invitationMarketingEffort
    additionalData
    eventRole
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateEventMutationVariables,
  APITypes.CreateEventMutation
>;
export const createEventUser = /* GraphQL */ `mutation CreateEventUser($input: CreateEventUserInput!) {
  createEventUser(input: $input) {
    eventId
    version
    sourceSiteId
    sourceName
    eventName
    ownerEmail
    eventKey
    ownerFirstName
    ownerLastName
    crmId
    live
    date
    endDate
    startTime
    endTime
    locale
    location
    room
    address
    address2
    city
    state
    zipCode
    description
    calendarStatus
    department
    marketingEffort
    invitationMarketingEffort
    additionalData
    eventRole
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateEventUserMutationVariables,
  APITypes.CreateEventUserMutation
>;
export const updateEvent = /* GraphQL */ `mutation UpdateEvent($input: UpdateEventInput!) {
  updateEvent(input: $input) {
    eventId
    version
    sourceSiteId
    sourceName
    eventName
    ownerEmail
    eventKey
    ownerFirstName
    ownerLastName
    crmId
    live
    date
    endDate
    startTime
    endTime
    locale
    location
    room
    address
    address2
    city
    state
    zipCode
    description
    calendarStatus
    department
    marketingEffort
    invitationMarketingEffort
    additionalData
    eventRole
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateEventMutationVariables,
  APITypes.UpdateEventMutation
>;
export const deleteEvent = /* GraphQL */ `mutation DeleteEvent($input: DeleteEventInput!) {
  deleteEvent(input: $input) {
    eventId
    version
    sourceSiteId
    sourceName
    eventName
    ownerEmail
    eventKey
    ownerFirstName
    ownerLastName
    crmId
    live
    date
    endDate
    startTime
    endTime
    locale
    location
    room
    address
    address2
    city
    state
    zipCode
    description
    calendarStatus
    department
    marketingEffort
    invitationMarketingEffort
    additionalData
    eventRole
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteEventMutationVariables,
  APITypes.DeleteEventMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser($input: DeleteUserInput!) {
  deleteUser(input: $input) {
    eventId
    version
    sourceSiteId
    sourceName
    eventName
    ownerEmail
    eventKey
    ownerFirstName
    ownerLastName
    crmId
    live
    date
    endDate
    startTime
    endTime
    locale
    location
    room
    address
    address2
    city
    state
    zipCode
    description
    calendarStatus
    department
    marketingEffort
    invitationMarketingEffort
    additionalData
    eventRole
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const createRegistration = /* GraphQL */ `mutation CreateRegistration($input: CreateRegistrationInput!) {
  createRegistration(input: $input) {
    registrationId
    eventId
    parentEventKey
    email
    crmId
    firstName
    lastName
    registrationStatus
    dateCreated
    qrBase64
    pdfBase64
    pdfUrl
    live
    guests
    additionalData
    submissionId
    checkedInCount
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRegistrationMutationVariables,
  APITypes.CreateRegistrationMutation
>;
export const updateRegistration = /* GraphQL */ `mutation UpdateRegistration($input: UpdateRegistrationInput!) {
  updateRegistration(input: $input) {
    registrationId
    eventId
    parentEventKey
    email
    crmId
    firstName
    lastName
    registrationStatus
    dateCreated
    qrBase64
    pdfBase64
    pdfUrl
    live
    guests
    additionalData
    submissionId
    checkedInCount
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateRegistrationMutationVariables,
  APITypes.UpdateRegistrationMutation
>;
export const deleteRegistration = /* GraphQL */ `mutation DeleteRegistration($input: DeleteRegistrationInput!) {
  deleteRegistration(input: $input) {
    registrationId
    eventId
    parentEventKey
    email
    crmId
    firstName
    lastName
    registrationStatus
    dateCreated
    qrBase64
    pdfBase64
    pdfUrl
    live
    guests
    additionalData
    submissionId
    checkedInCount
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteRegistrationMutationVariables,
  APITypes.DeleteRegistrationMutation
>;
export const createParticipation = /* GraphQL */ `mutation CreateParticipation($input: CreateParticipationInput!) {
  createParticipation(input: $input) {
    participationId
    registrationId
    eventId
    actorEmail
    actionType
    checkedInCount
    participationStatus
    geocoordinates
    crmId
    locationTag
    userAgent
    ipAddress
    deviceType
    deviceModel
    osType
    osVersion
    createdAt
    actionDetails
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateParticipationMutationVariables,
  APITypes.CreateParticipationMutation
>;
