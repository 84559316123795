import React from "react";
import KeyCloakService from "./security/keycloakService";
import './css/DropDownMenu.css';

type DropdownMenuProps = {
  onLogout: () => void;
};

const DropdownMenu: React.FC<DropdownMenuProps> = ({ onLogout }) => {

  const firstName = KeyCloakService.GetUserFirstName();
  const lastName = KeyCloakService.GetUserLastName();

  return (
    <div className="dropdown-menu">
      <div>
        <h3>{firstName + ' ' + lastName}</h3>
      </div>
      <button onClick={onLogout}>Logout</button>
    </div>
  );
};

export default DropdownMenu;
