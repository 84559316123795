import { gql } from '@apollo/client';
import { getApolloClient} from '../apolloClient';
import { createEventUser } from '../graphql/mutations';
import { CreateEventUserInput } from '../API';

export type CreateEventUserResponse = {
    createEventUser: {
        eventId: string;
        eventRole: string;
        ownerEmail: string;
    };
};

export async function addUserToEvent(input: CreateEventUserInput): Promise<CreateEventUserResponse> {
    console.log('addEventUser input: ', input);
    try {
        const client = await getApolloClient();
        const { data } = await client.mutate<{ createEventUser: CreateEventUserResponse }>({
            mutation: gql`
                ${createEventUser}
            `,
            variables: { input },
        });
        if (!data) {
            throw new Error('No data returned from mutation');
        }
        return data.createEventUser;
    } catch (err) {
        console.error('Error creating event user:', err);
        throw err;
    }
}