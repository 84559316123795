/* eslint-disable */
import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import "./css/FileUpload.css"; // Add a CSS file for styling

const GENERATE_PRESIGNED_URL = gql`
  mutation GeneratePresignedUrl($input: GeneratePresignedUrlInput!) {
    generatePresignedUrl(input: $input) {
      url
      bucketName
      key
    }
  }
`;

interface FileUploadProps {
  eventId: string;
}

const FileUpload: React.FC<FileUploadProps> = ({ eventId }) => {
  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);

  const [generatePresignedUrl] = useMutation(GENERATE_PRESIGNED_URL);

  const requiredColumns = [
    "Account",
    "Name",
    "Preferred Email",
    "Wooden Athletic Fund",
    "External ID",
  ];

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError(null);
    if (event.target.files && event.target.files[0]) {
      const selectedFile = event.target.files[0];
      if (selectedFile.type !== "text/csv") {
        setError("Please select a valid CSV file.");
        return;
      }
      setFile(selectedFile);
    }
  };

  const validateCSV = (csvText: string): boolean => {
    const lines = csvText.split("\n");
    const headers = lines[0]?.split(",").map((header) => header.trim());

    const missingColumns = requiredColumns.filter(
      (col) => !headers.includes(col)
    );

    if (missingColumns.length > 0) {
      setError(
        `The CSV is missing required columns: ${missingColumns.join(", ")}`
      );
      return false;
    }

    return true;
  };

  const handleUpload = async () => {
    if (!file) {
      setError("Please select a file to upload.");
      return;
    }

    setIsUploading(true);
    setError(null);

    try {
      // Generate a new filename using eventId and timestamp
      const timestamp = Date.now();
      const newFilename = `${eventId}_${timestamp}.csv`;

      // Read file content for validation
      const fileContent = await file.text();
      if (!validateCSV(fileContent)) {
        setIsUploading(false);
        return;
      }

      // Get presigned URL
      const { data } = await generatePresignedUrl({
        variables: {
          input: {
            filename: newFilename,
            fileType: file.type,
          },
        },
      });

      const presignedUrl = data.generatePresignedUrl.url;

      // Rename the file before uploading
      const renamedFile = new File([file], newFilename, { type: file.type });

      // Upload file to S3
      await fetch(presignedUrl, {
        method: "PUT",
        body: renamedFile,
        headers: {
          "Content-Type": file.type,
        },
      });

      alert("File uploaded successfully!");
      setTimeout(() => {
        window.location.reload(); // Force a refresh after 6 seconds
      }, 6000);
    } catch (err) {
      console.error("Error uploading file:", err);
      setError("An error occurred during the upload. Please try again.");
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="file-upload-container">
      <h3 className="file-upload-title">Upload Registrations CSV</h3>
      <input
        type="file"
        accept=".csv"
        onChange={handleFileChange}
        className="file-upload-input"
      />
      {file && <p className="file-upload-selected">Selected File: {file.name}</p>}
      {error && <p className="file-upload-error">{error}</p>}
      <button
        onClick={handleUpload}
        disabled={isUploading}
        className={`file-upload-button ${isUploading ? "disabled" : ""}`}
      >
        {isUploading ? (
          <div className="spinner"></div>
        ) : (
          "Upload"
        )}
      </button>
    </div>
  );
};

export default FileUpload;