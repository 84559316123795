// VideoStream.tsx
import { useEffect, useRef } from "react";
import QrScanner from "qr-scanner";
import './css/VideoStream.css';

interface VideoStreamProps {
  deviceId: string | null;
  disableScanning: boolean;
  setScanResult: (result: string) => void;
  highlightScanRegion?: boolean;
  highlightCodeOutline?: boolean;
  maxScansPerSecond?: number;
}

function VideoStream(props: VideoStreamProps) {
  const {
    deviceId,
    disableScanning,
    setScanResult,
    highlightScanRegion = false,
    highlightCodeOutline = false,
    maxScansPerSecond = 25
  } = props;

  const videoRef = useRef<HTMLVideoElement>(null);
  const scannerRef = useRef<QrScanner | null>(null);

  // calculates the scan region (center of the video)
  const calculateScanRegion = (video: HTMLVideoElement) => {
    const width = video.videoWidth * 0.7;
    const height = video.videoHeight * 0.7;
    const x = (video.videoWidth - width) / 2;
    const y = (video.videoHeight - height) / 2;

    return { x, y, width, height };  // Returns region as object
  };

  useEffect(() => {
    const startScanner = async () => {
      if (videoRef.current !== null && deviceId !== null && scannerRef.current === null) {
        try {
          // Request higher resolution for better QR scanning
          const constraints = {
            video: {
              deviceId: deviceId,  // Use the selected device (back camera)
              width: { ideal: 1280 },  // Higher resolution width
              height: { ideal: 720 },  // Higher resolution height
              facingMode: { ideal: 'environment' },  // Rear camera preferred for mobile
            }
          };

          // Get camera stream with the specified resolution
          const stream = await navigator.mediaDevices.getUserMedia(constraints);
          videoRef.current.srcObject = stream;

          // Initialize the QR Scanner
          scannerRef.current = new QrScanner(
            videoRef.current,
            result => {
              if (!disableScanning) {
                setScanResult(result.data.replace(/\"/g, ""));
              }
            },
            {
              onDecodeError: err => console.log("Decode Error:", err),
              preferredCamera: deviceId,
              highlightScanRegion: highlightScanRegion,
              highlightCodeOutline: highlightCodeOutline,
              maxScansPerSecond: maxScansPerSecond,
              calculateScanRegion: calculateScanRegion  // Focus scan on center region
            }
          );

          scannerRef.current.start();
        } catch (e) {
          console.log('Failed to initialize scanner:', e);
        }
      }
    };

    const stopScanner = () => {
      if (scannerRef.current) {
        scannerRef.current.stop();
        scannerRef.current.destroy();
        scannerRef.current = null;
      }
    };

    if (!disableScanning && deviceId) {
      startScanner();
    } else {
      stopScanner();
    }

    return () => {
      stopScanner();
    };
  }, [deviceId, disableScanning, highlightScanRegion, highlightCodeOutline, maxScansPerSecond]);

  return (
    <video
      style={{ width: "100%", height: "auto", objectFit: "cover" }}
      ref={videoRef}
    />
  );
}

export default VideoStream;