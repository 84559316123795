import React, { useState, useEffect, useRef } from "react";
import KeyCloakService from "./security/keycloakService";
import DropdownMenu from "./DropDownMenu";

const Banner: React.FC = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const getUserInitials = (): string | undefined => {
    const firstName = KeyCloakService.GetUserFirstName();
    const lastName = KeyCloakService.GetUserLastName();

    if (!firstName || !lastName) return undefined;

    const initials = `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;
    return initials;
  };

  const handleProfileClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLogout = async () => {
    try {
      await KeyCloakService.CallLogout();
    } catch (error) {
      console.error('Logout failed', error);
    }
  };


  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };

    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen]);

  return (
    <header className="banner-header">
      <div className="profile" onClick={handleProfileClick}>
        {getUserInitials()}
      </div>
      {isDropdownOpen && (
        <div ref={dropdownRef}>
          <DropdownMenu 
            onLogout={() => { handleLogout().catch(console.error); }} 
          />
        </div>
      )}
    </header>
  );
};

export default Banner;