// apolloClient.tsx
/* eslint-disable */
import { ApolloClient, InMemoryCache, HttpLink, ApolloLink, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { print } from 'graphql/language/printer';

const GRAPHQL_ENDPOINT = import.meta.env.VITE_GRAPHQL_ENDPOINT as string;
const GRAPHQL_WS_ENDPOINT = GRAPHQL_ENDPOINT.replace('https', 'wss').replace('appsync-api', 'appsync-realtime-api') + '/realtime';

let apolloClient: ApolloClient<any> | null = null;

interface Headers {
  [key: string]: string;
}

interface ConnectionDetails {
  api_header: Headers;
  connection_url: string;
}


const createApolloClient = async () => {
  let token = localStorage.getItem('kc_idToken');

  // development bypass for token
  if (process.env.NODE_ENV === 'development') {
    // Mock authentication in development mode
    console.log('Development mode: bypassing authentication');
    token = 'dev-mock-token';  // Mock token for development
  }

  if (!token) {
    throw new Error('JWT token is missing');
  }

  const getConnection = (accessToken: string): ConnectionDetails => {
    const host = GRAPHQL_ENDPOINT.replace('https://', '').replace('/graphql', '');

    // Use a plain object for headers 
    const api_header: Headers = {
      host: host,
      authorization: accessToken,
    };

    const header_encode = (obj: Record<string, unknown>) => btoa(JSON.stringify(obj));
    const connection_url = GRAPHQL_WS_ENDPOINT + '?header=' + header_encode(api_header) + '&payload=' + header_encode({});
    return { api_header, connection_url };
  };

  const createAppSyncGraphQLOperationAdapter = (api_header: Headers) => ({
    applyMiddleware: async (options: Record<string, unknown>, next: () => void) => {
      options.data = JSON.stringify({
        query: typeof options.query === 'string' ? options.query : print(options.query as any),
        variables: options.variables,
      });
      options.extensions = { authorization: api_header };
      delete options.operationName;
      delete options.variables;
      next();
    }
  });

  const getNewSocket = (accessToken: string) => {
    const { connection_url, api_header } = getConnection(accessToken);
    const wsLink = new WebSocketLink(
      new SubscriptionClient(
        connection_url,
        {
          timeout: 5 * 60 * 1000,
          reconnect: true,
          lazy: true,
        },
        typeof WebSocket !== 'undefined' ? WebSocket : undefined
      ).use([createAppSyncGraphQLOperationAdapter(api_header)])
    );
    return wsLink;
  };

  const httpLink = new HttpLink({
    uri: GRAPHQL_ENDPOINT,
  });

  const authLink = setContext((_, { headers }: { headers?: Headers }) => {
    if (!token) {
      console.error('No token found!');
      return {
        headers,
      };
    }
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    };
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    getNewSocket(token),
    authLink.concat(httpLink)
  );

  return new ApolloClient({
    link: ApolloLink.from([splitLink]),
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
      },
    },
  });
};

export const getApolloClient = async () => {
  if (!apolloClient) {
    apolloClient = await createApolloClient();
  }
  return apolloClient;
};