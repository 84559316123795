/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onCreateEvent = /* GraphQL */ `subscription OnCreateEvent(
  $eventId: String
  $sourceSiteId: String
  $sourceName: String
  $eventName: String
  $location: String
) {
  onCreateEvent(
    eventId: $eventId
    sourceSiteId: $sourceSiteId
    sourceName: $sourceName
    eventName: $eventName
    location: $location
  ) {
    eventId
    version
    sourceSiteId
    sourceName
    eventName
    ownerEmail
    eventKey
    ownerFirstName
    ownerLastName
    crmId
    live
    date
    endDate
    startTime
    endTime
    locale
    location
    room
    address
    address2
    city
    state
    zipCode
    description
    calendarStatus
    department
    marketingEffort
    invitationMarketingEffort
    additionalData
    eventRole
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateEventSubscriptionVariables,
  APITypes.OnCreateEventSubscription
>;
export const onUpdateEvent = /* GraphQL */ `subscription OnUpdateEvent(
  $eventId: String
  $sourceSiteId: String
  $sourceName: String
  $eventName: String
  $location: String
) {
  onUpdateEvent(
    eventId: $eventId
    sourceSiteId: $sourceSiteId
    sourceName: $sourceName
    eventName: $eventName
    location: $location
  ) {
    eventId
    version
    sourceSiteId
    sourceName
    eventName
    ownerEmail
    eventKey
    ownerFirstName
    ownerLastName
    crmId
    live
    date
    endDate
    startTime
    endTime
    locale
    location
    room
    address
    address2
    city
    state
    zipCode
    description
    calendarStatus
    department
    marketingEffort
    invitationMarketingEffort
    additionalData
    eventRole
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateEventSubscriptionVariables,
  APITypes.OnUpdateEventSubscription
>;
export const onDeleteEvent = /* GraphQL */ `subscription OnDeleteEvent(
  $eventId: String
  $sourceSiteId: String
  $sourceName: String
  $eventName: String
  $location: String
) {
  onDeleteEvent(
    eventId: $eventId
    sourceSiteId: $sourceSiteId
    sourceName: $sourceName
    eventName: $eventName
    location: $location
  ) {
    eventId
    version
    sourceSiteId
    sourceName
    eventName
    ownerEmail
    eventKey
    ownerFirstName
    ownerLastName
    crmId
    live
    date
    endDate
    startTime
    endTime
    locale
    location
    room
    address
    address2
    city
    state
    zipCode
    description
    calendarStatus
    department
    marketingEffort
    invitationMarketingEffort
    additionalData
    eventRole
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteEventSubscriptionVariables,
  APITypes.OnDeleteEventSubscription
>;
export const onCreateRegistration = /* GraphQL */ `subscription OnCreateRegistration($eventId: String) {
  onCreateRegistration(eventId: $eventId) {
    registrationId
    eventId
    parentEventKey
    email
    crmId
    firstName
    lastName
    registrationStatus
    dateCreated
    qrBase64
    pdfBase64
    pdfUrl
    live
    guests
    additionalData
    submissionId
    checkedInCount
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateRegistrationSubscriptionVariables,
  APITypes.OnCreateRegistrationSubscription
>;
export const onUpdateRegistration = /* GraphQL */ `subscription OnUpdateRegistration($eventId: String) {
  onUpdateRegistration(eventId: $eventId) {
    registrationId
    eventId
    parentEventKey
    email
    crmId
    firstName
    lastName
    registrationStatus
    dateCreated
    qrBase64
    pdfBase64
    pdfUrl
    live
    guests
    additionalData
    submissionId
    checkedInCount
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateRegistrationSubscriptionVariables,
  APITypes.OnUpdateRegistrationSubscription
>;
export const onDeleteRegistration = /* GraphQL */ `subscription OnDeleteRegistration($eventId: String) {
  onDeleteRegistration(eventId: $eventId) {
    registrationId
    eventId
    parentEventKey
    email
    crmId
    firstName
    lastName
    registrationStatus
    dateCreated
    qrBase64
    pdfBase64
    pdfUrl
    live
    guests
    additionalData
    submissionId
    checkedInCount
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteRegistrationSubscriptionVariables,
  APITypes.OnDeleteRegistrationSubscription
>;
