import { FormEvent, useEffect, useState } from "react";

interface CameraSelectProps {
  changeInputStream: (deviceId: string | null) => void;
}

function CameraSelect(props: CameraSelectProps) {
  const { changeInputStream } = props;
  const [options, setOptions] = useState<Array<MediaDeviceInfo>>([]);
  const [selectedOption, setSelected] = useState("none");
  const [message, setMessage] = useState("Loading media devices...");

  function selectCamera(e: FormEvent<HTMLSelectElement>) {
    const { value } = e.currentTarget;
    
    setSelected(value);
    if (value === "none") {
      changeInputStream(null);
      localStorage.removeItem("selectedCamera"); 
    } else {
      changeInputStream(value);
      localStorage.setItem("selectedCamera", value);
    }
  }

  useEffect(() => {
    // Load available devices
    navigator.mediaDevices.enumerateDevices().then(
      (devices) => {
        const videoDevices: Array<MediaDeviceInfo> = devices.filter(
          (device) => device.kind.includes("video")
        );
        if (videoDevices.length) {
          setMessage("Please select a camera from the list below:");
          setOptions(videoDevices);

          // Check if a device was previously selected
          const savedDeviceId = localStorage.getItem("selectedCamera");
          if (savedDeviceId) {
            setSelected(savedDeviceId);
            changeInputStream(savedDeviceId); // Automatically set the saved camera
          }
        }
      }
    ).catch((err) => {
      console.log("enumeration failed", err);
    });
  }, [changeInputStream]);

  return (
    <select
      name="cameraSelect"
      onChange={selectCamera}
      value={selectedOption} // Use value to reflect current selection
      className="camera-select"
    >
      <option key="default" value="none">{message}</option>
      {options?.map((option: MediaDeviceInfo, idx) => (
        <option key={idx} value={option.deviceId}>
          {option.label || option.kind}
        </option>
      ))}
    </select>
  );
}

export default CameraSelect;