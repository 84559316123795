import { gql } from '@apollo/client';
import { getApolloClient} from '../apolloClient';
import { createParticipation } from '../graphql/mutations';
import { CreateParticipationInput } from '../API';

export type CreateParticipationResponse = {
    createParticipation: {
        eventId: string;
        registrationId: string;
        participationId: string;
        createdAt: string;
    };
};

export async function createParticipationRecord(input: CreateParticipationInput): Promise<CreateParticipationResponse> {
    console.log('createParticipation input: ', input);
    try {
        const client = await getApolloClient();
        const { data } = await client.mutate<{ createParticipation: CreateParticipationResponse }>({
            mutation: gql`
                ${createParticipation}
            `,
            variables: { input },
        });
        if (!data) {
            throw new Error('No data returned from mutation');
        }
        return data.createParticipation;
    } catch (err) {
        console.error('Error creating event user:', err);
        throw err;
    }
}